@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind colors;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* body{
  display: block;
} */
/* static|absolute|fixed|relative|sticky|initial|inherit; */


/* Custom Css starts herr */

/* h1 {
  font-size: 35px;
  font-family: "Rubik";

  color: #fff;
  width: 100%;
  text-align: center;
}
h3 {
  font-family: "Rubik";
  text-align: center;
  color: #ffffff55;
}
p {
  font-family: "Rubik";
  text-align: center;
  color: #ffffff55;
}

#hi {
  position: fixed;
  display: flex;
  z-index: 999;
  flex-direction: column;
}

.buttonCustom {
     background-color: #4caf50 !important;
    border: none !important;
    color: white !important;
    border-radius: 20px !important;
    padding: 10px 22px !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    font-size: 16px !important;
}

a {
  text-decoration: none;
  color: white;
  font-size: 20px;
  font-family: "Rubik";
}

html {
  cursor: crosshair;
} */

/* body {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(https://images.unsplash.com/photo-1555993539-1732b0258235?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80);
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
} */

#c,
canvas {
  /* width: 10; */
  height: 100px;
  /* margin: 0;
  padding: 0; */
  display: block;
  overflow: hidden;
  position: relative;
  z-index: 0;
  opacity: 0.7;
}

#c {
  display: none;
}

/* #root{
  position: relative;
} */
.home_hero_section {
  background-image: url(./assets/matrix.gif);
  background-size: cover;
  /* background-color: black; */
  /* height: 100px; */
  /* position: relative; */
  /* z-index: 999;  */
}

.knowledge_landing_hero_section {
  background-image: url(./assets/giphy.gif);
  background-size: cover;
  /* background-color: black; */
  /* height: 100px; */
  /* position: relative; */
  /* z-index: 999;  */
}

.knowledge_hero_section {
  background-image: url(./assets/matrix.gif);
  background-size: cover;
  /* background-color: black; */
  /* height: 100px; */
  /* position: relative; */
  /* z-index: 999;  */
}


.home_gallery_section{
    background-image: url(./assets/section_cover.webp);
    background-size: cover; 
    background-color: black;
    background-position: center center; 
}




.titktok_iframe_container {
  left: 0;
    width: "100%";
    height: 500;
    position: "relative";
}

.titktok_iframe {
    top: 0;
    left: 0;
    width: auto;
    height: 500px;
    position: relative;
    border: 0px;
}


#tsparticles {
  position: absolute;
  width: 100%;
  height: 300;
}

/* .knowledge_formatting p{
    
    color: black;
    width: 100%;
    
} */